<script>
import { BOverlay } from "bootstrap-vue";
import store from "@/store";
import vltTemplatesModule from "@/views/pages/templates/vlt-templates/store/vltStoreModule";
import { mapState } from "vuex";

const MODULE_VLT_STORE_NAME = "vlt-templates";

export default {
  name: "VLTCreatingProgress",
  components: { BOverlay },
  props: {
    createTemplate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errorCreatingTemplates: false,
    };
  },
  computed: {
    ...mapState("vlt-templates", ["loadingAdd"]),

    creatingText() {
      return `${this.$t('creating')} ${this.$t('template')}: ${this.createTemplate.name}...`
    }
  },
  async mounted() {
    try {
      const params = {
        ...this.createTemplate,
        whitelabelId: this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
      };
      const { data: template } = await this.$store.dispatch(
          "vlt-templates/addTemplate",
          params
      );
      const routeName = `${this.createTemplate.type}-vtl-template-preview`;
      await this.$router.push({ name: routeName, query: { id: template._id } });
    } catch (error) {
      this.errorCreatingTemplates = true;
      this.$emit("onError");
      console.error("Error creating template:", error);
    }
  },
  beforeCreate() {
    if (!store.hasModule(MODULE_VLT_STORE_NAME)) {
      store.registerModule(MODULE_VLT_STORE_NAME, vltTemplatesModule);
    }
  },
  beforeDestroy() {
    if (store.hasModule(MODULE_VLT_STORE_NAME)) {
      store.unregisterModule(MODULE_VLT_STORE_NAME);
    }
  },
};
</script>

<template>
  <div class="pb-5">
    <b-overlay opacity="0" :show="true" class="mt-4">
      <div class="py-2"></div>
    </b-overlay>
    <p class="text-center">{{ creatingText }}</p>
  </div>
</template>

<style scoped lang="scss"></style>
