<script>
import {
  BButton,
  BCard,
  BCardTitle,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { required } from "@core/utils/validations/validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import error from "@/views/pages/miscellaneous/Error.vue";

export default {
  name: "TemplateForm",
  computed: {
    error() {
      return error;
    },
  },
  components: {
    BFormInput,
    BFormCheckbox,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    BFormGroup,
    BCard,
    BCardTitle,
  },
  data() {
    return {
      form: {
        name: "",
        checked: [],
      },
      show: true,
    };
  },
  methods: {
    onSubmit() {
      if (!this.form.name) return;
      this.$emit("onSubmit",  this.form.name );
    },
  },
};
</script>

<template>
  <div class="col-md-6 mx-auto py-4">
    <validation-observer ref="templateForm">
      <b-form class="auth-login-form mt-2" @submit.prevent="onSubmit">
        <b-form-group :label="$t('template_name') + ':'" label-for="input-1">
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-input
              id="input-1"
              v-model="form.name"
              :state="errors.length > 0 ? false : null"
              name="login-username"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- submit button-->
        <b-button type="submit" class="w-100" variant="primary">
          {{ $t('buttons.accept') }}
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<style scoped lang="scss"></style>
